<template>
  <v-card :height="cardHeight">
    <v-card-title class="mb-0 pb-1">
      Meter Information
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-container class="mt-0">
        <v-row>
          <v-col
            sm="4"
            md="3"
            cols="12"
            class="mb-4"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Meter number
              </h4>
              <h4 class="font-weight-medium">
                {{ meter.number }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Meter mode
              </h4>
              <h4 class="font-weight-medium">
                {{ meter.mode | formatMeterMode }}
              </h4>
            </div>
          </v-col>
          <v-col
            v-if="meter.type_id == null || (meter.type && meter.type.name !== 'Prepaid')"
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Last monthly reading
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ meter.last_reading }}
              </h4>
            </div>
          </v-col>
          <v-col
            v-if="meter.type && meter.type.name !== 'Prepaid'"
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Last monthly reading date
              </h4>
              <h4
                v-if="meter.last_reading_date != null"
                class="font-weight-medium"
              >
                {{ meter.last_reading_date | formatDate }}
              </h4>
              <h4
                v-else
                class="font-weight-medium"
              >
                No readings found
              </h4>
            </div>
          </v-col>
          <v-col
            v-if="meter.type && meter.type.name !== 'Prepaid'"
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Current reading
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ meter.current_reading }}
              </h4>
            </div>
          </v-col>
          <v-col
            v-if="meter.mode == 1 && meter.type && meter.type.name !== 'Prepaid'"
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer v-show="isLoading" />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Last communication date
              </h4>
              <h4
                class="font-weight-medium"
              >
                {{ meter.last_communication_date | formatDate }}
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Sim card number
              </h4>
              <h4
                v-if="meter.sim_card_number !== null"
                class="font-weight-medium"
              >
                {{ meter.sim_card_number }}
              </h4>
              <h4 v-else>
                Not provided
              </h4>
            </div>
          </v-col>
          <v-col
            v-if="meter.mode == 1 && meter.type && meter.type.name != 'Prepaid'"
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Valve status
              </h4>
              <h4
                class="font-weight-medium"
              >
                <v-switch
                  v-model="meter.valve_status"
                  inset
                  :loading="isToggleValveSwitchLoading"
                  :disabled="isToggleValveSwitchLoading"
                  :false-value="0"
                  :true-value="1"
                  :label="meter.valve_status ? 'Open' : 'Closed'"
                  @change="onValveSwitchToggle"
                ></v-switch>
              </h4>
            </div>
          </v-col>
          <v-col
            v-if="meter.type && meter.type.name === 'Prepaid'"
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light">
                Can generate token
              </h4>
              <h4
                class="font-weight-medium"
              >
                <v-switch
                  v-model="meter.can_generate_token"
                  inset
                  :loading="isToggleCanGenerateTokenSwitchLoading"
                  :disabled="isToggleCanGenerateTokenSwitchLoading"
                  :false-value="0"
                  :true-value="1"
                  :label="meter.can_generate_token ? 'Yes' : 'No'"
                  @change="onCanGenerateTokenSwitchToggle"
                ></v-switch>
              </h4>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div
              v-if="meter.type && meter.type.name === 'Prepaid'"
              v-show="!isLoading"
            >
              <v-btn
                small
                depressed
                color="primary"
                class="mt-4"
                @click="showResetTokenDialog()"
              >
                <v-icon
                  left
                  size="16"
                >
                  {{ icons.mdiLockReset }}
                </v-icon>
                Reset Tokens
              </v-btn>
            </div>
          </v-col>
          <v-col
            sm="4"
            md="3"
            cols="12"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div
              v-if="meter.type && meter.type.name === 'Prepaid'"
              v-show="!isLoading"
            >
              <v-btn
                small
                depressed
                color="primary"
                class="mt-4"
                @click="showClearTamperDialog()"
              >
                <v-icon
                  left
                  size="16"
                >
                  {{ icons.mdiLockClock }}
                </v-icon>
                Clear Tamper
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="mt-0"
          >
            <details-text-shimmer
              v-show="isLoading"
              class="mt-2 mb-3"
            />
            <div v-show="!isLoading">
              <h4 class="font-weight-light mb-2">
                Location
              </h4>
              <h4>{{ meter.location }}</h4><br>
              <div class="map">
                <iframe
                  v-if="meter.has_location_coordinates"
                  title="meter-location"
                  width="1300px"
                  height="260"
                  :src="'https://maps.google.com/maps?q=' + meter.lat +','+meter.lng + '&t=&z=13&ie=UTF8&iwloc=&output=embed'"
                ></iframe>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <confirm-dialog
      :show-dialog="showConfirmToggleValveDialog"
      :is-agree-button-loading="isToggleValveSwitchLoading"
      :message="'Are you sure you want to switch off valve of this meter?'"
      :agree-text="'Switch Off'"
      @cancel="cancelToggleValveStatusUpdate()"
      @agree="updateValveStatus()"
    />
    <confirm-dialog
      :show-dialog="showConfirmToggleGenerateTokenDialog"
      :is-agree-button-loading="isToggleCanGenerateTokenSwitchLoading"
      :message="'Are you sure you want to disable this meter from generating token?'"
      :agree-text="'Disable'"
      @cancel="cancelToggleGenerateTokenStatusUpdate()"
      @agree="updateCanGenerateTokenStatus()"
    />
    <confirm-dialog
      :show-dialog="showConfirmResetTokenDialog"
      :is-agree-button-loading="isResetTokenLoading"
      :message="'Are you sure you want to reset tokens of this meter?'"
      :agree-text="'Reset'"
      @cancel="showConfirmResetTokenDialog = false"
      @agree="clearToken()"
    />

    <confirm-dialog
      :show-dialog="showConfirmClearTamperDialog"
      :is-agree-button-loading="isClearTamperLoading"
      :message="'Are you sure you want to clear tamper of this meter?'"
      :agree-text="'Clear'"
      @cancel="showConfirmClearTamperDialog = false"
      @agree="clearTamper()"
    />
    <clear-token-action-dialog
      :show-dialog="showSuccessResetTokenDialog"
      :meter-id="meter.id"
      :title="'Token reset successfully'"
      :message="'Please enter the following token into the meter to complete the reset.'"
      :secondary-message="resetToken"
      :agree-text="'SEND'"
      @cancel="showSuccessResetTokenDialog = false"
    />

    <clear-token-action-dialog
      :show-dialog="showSuccessClearTamperDialog"
      :meter-id="meter.id"
      :title="'Tamper cleared successfully'"
      :message="'Please enter the following token into the meter to complete the clear tamper.'"
      :secondary-message="clearTamperToken"
      :agree-text="'SEND'"
      @cancel="showSuccessClearTamperDialog = false"
    />
  </v-card>
</template>

<script>
import axios from 'axios'
import { mdiLockReset, mdiLockClock } from '@mdi/js'
import DetailsTextShimmer from '@/components/partials/shimmers/DetailsTextShimmer.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import ClearTokenActionDialog from '../dialogs/ClearTokenActionDialog.vue'

export default {
  components: {
    DetailsTextShimmer,
    ConfirmDialog,
    ClearTokenActionDialog,
  },
  props: {
    meter: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showConfirmToggleValveDialog: false,
      isToggleValveSwitchLoading: false,
      showConfirmToggleGenerateTokenDialog: false,
      isToggleCanGenerateTokenSwitchLoading: false,
      showConfirmResetTokenDialog: false,
      showSuccessResetTokenDialog: false,
      isResetTokenLoading: false,
      resetToken: '',
      showConfirmClearTamperDialog: false,
      showSuccessClearTamperDialog: false,
      isClearTamperLoading: false,
      clearTamperToken: '',
      cardHeight: 310,
      icons: {
        mdiLockReset,
        mdiLockClock,
      },
    }
  },
  watch: {
    meter: {
      handler() {
        this.cardHeight = this.meter.has_location_coordinates ? 600 : 310
      },
      immediate: true,
    },
  },
  methods: {
    showResetTokenDialog() {
      this.showConfirmResetTokenDialog = true
    },
    showClearTamperDialog() {
      this.showConfirmClearTamperDialog = true
    },
    onValveSwitchToggle() {
      if (this.meter.valve_status === 1) {
        this.updateValveStatus()

        return
      }
      this.showConfirmToggleValveDialog = true
    },
    onCanGenerateTokenSwitchToggle() {
      if (this.meter.can_generate_token === 1) {
        this.updateCanGenerateTokenStatus()

        return
      }
      this.showConfirmToggleGenerateTokenDialog = true
    },
    cancelToggleValveStatusUpdate() {
      this.showConfirmToggleValveDialog = false
      this.meter.valve_status = (this.meter.valve_status === 0 ? 1 : 0)
    },
    cancelToggleGenerateTokenStatusUpdate() {
      this.showConfirmToggleGenerateTokenDialog = false
      this.meter.can_generate_token = (this.meter.can_generate_token === 0 ? 1 : 0)
    },
    updateValveStatus() {
      this.showConfirmToggleValveDialog = false
      this.isToggleValveSwitchLoading = true
      let action = 'closed'
      if (this.meter.valve_status === 1) {
        action = 'opened'
      }
      axios
        .put(`valve-status/${this.meter.id}`, {
          valve_status: this.meter.valve_status,
        })
        .then(response => {
          this.isToggleValveSwitchLoading = false
          this.$notification.success(`Valve for meter ${response.data} has been ${action} successfully`)
        })
        .catch(error => {
          this.cancelToggleValveStatusUpdate()
          this.isToggleValveSwitchLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    updateCanGenerateTokenStatus() {
      this.showConfirmToggleGenerateTokenDialog = false
      this.isToggleCanGenerateTokenSwitchLoading = true
      let action = 'disabled'
      if (this.meter.can_generate_token === 1) {
        action = 'enabled'
      }
      axios
        .put(`can-generate-token/${this.meter.id}`, {
          can_generate_token: this.meter.can_generate_token,
        })
        .then(response => {
          this.isToggleCanGenerateTokenSwitchLoading = false
          this.$notification.success(`Token generation for meter ${response.data} has been ${action} successfully`)
        })
        .catch(error => {
          this.cancelToggleGenerateTokenStatusUpdate()
          this.isToggleCanGenerateTokenSwitchLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    clearToken() {
      this.isResetTokenLoading = true
      axios
        .post('meter-tokens-clear', {
          meter_id: this.meter.id,
        })
        .then(response => {
          this.$notification.success('cleared successfully')
          this.showConfirmResetTokenDialog = false
          this.showSuccessResetTokenDialog = true
          this.resetToken = response.data
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        }).finally(() => {
          this.isResetTokenLoading = false
        })
    },
    clearTamper() {
      this.isClearTamperLoading = true
      axios
        .post('meter-clear-tamper-record', {
          meter_id: this.meter.id,
        })
        .then(response => {
          this.$notification.success('cleared successfully')
          this.showConfirmClearTamperDialog = false
          this.showSuccessClearTamperDialog = true
          this.clearTamperToken = response.data
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        }).finally(() => {
          this.isClearTamperLoading = false
        })
    },
  },
}
</script>

<style>
.map{
  max-width: 1300px;
  overflow: hidden;
}
</style>
